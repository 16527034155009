import React from 'react';
import { Parallax } from 'react-scroll-parallax';

function AirMaxExecutionMobile({ images }) {
  const [amImgMobile06, amImgMobile07] = images;

  return (
    <div className="am-execution">
      <h3 className="main-heading execution am-execution__heading">
        Execution
      </h3>
      <p className="main-copy am-execution__copy">
        Working from our strategic research we identified the most authentic narrative,
        location and action for each of our talents. We then collaborated with each talent to dial in the details before shooting them each in the place they called home in cities around the US and captured a mix of live action footage and still photography to bring forth an interesting mix of both.
      </p>
      <Parallax className="blockquote am-execution__blockquote" y={[40, -20]}>
        <blockquote className="blockquote">
          &ldquo;…AND PERSONAL IDENTITY THROUGH THE LENS OF MODERN YOUTH CULTURE.&rdquo;
        </blockquote>
      </Parallax>
      <Parallax className="air-max__img air-max__img--6" y={[40, -20]}>
        <img src={amImgMobile06} alt="" />
      </Parallax>
      <div className="air-max__img air-max__img--7">
        <img src={amImgMobile07} alt="" />
      </div>
    </div>
  );
}

export default AirMaxExecutionMobile;