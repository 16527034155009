import React from 'react';
// components
import { NikeIcon } from 'src/components/svgs';
import VideoPlayer from 'src/components/VideoPlayer';

function AirMaxChallenge({ images, videos }) {
  const [amImg01, amImg02] = images;
  const [amVid01] = videos;

  return (
    <div className="am-challenge">
      <div className="am-challenge__header">
        <NikeIcon modifier="am-challenge__header-logo" />
        <h3 className="main-heading am-challenge__heading">Air Max 90</h3>
        <p className="main-copy am-challenge__subheading">NIKE X FOOTLOCKER</p>
      </div>
      <h3 className="main-heading am-challenge__title">Challenge</h3>
      <p className="main-copy am-challenge__copy">
        How do we re-introduce the classic Air Max sneaker, originally known for being the bold, defiant symbol of self-expression 30 years ago, to a whole new generation of sneaker fans amid a wildly different world?
      </p>
      <p className="main-copy am-challenge__tagline">
        HERO FILM: “IRL” Longform
      </p>

      <VideoPlayer className="air-max__video air-max__video--1" src={amVid01} />
      <div className="air-max__img air-max__img--1">
        <img src={amImg01} alt="" />
      </div>
      <div className="air-max__img air-max__img--2">
        <img src={amImg02} alt="" />
      </div>
    </div>
  );
}

export default AirMaxChallenge;