import React from 'react';
// components
import AirMaxChallenge from './AirMaxChallenge';
import AirMaxSolution from './AirMaxSolution';
import AirMaxExecution from './AirMaxExecution';
import AirMaxImpact from './AirMaxImpact';
import SectionTrigger from '../SectionTrigger';

function AirMaxContent({ images, videos }) {
  const [
    amImg01,
    amImg02,
    amImg03,
    amImg04,
    amImg05,
    amImg06,
    amImg07,
    amImg08,
    amImg09,
    amImg10,
    amImg11,
    amImg12,
  ] = images;
  const [ amVid01 ] = videos;

  return (
    <div>
      <div className="air-max__container">
        <AirMaxChallenge images={[amImg01, amImg02]} videos={[amVid01]} />
        <AirMaxSolution images={[amImg03, amImg04, amImg05, amImg06]} />
        <AirMaxExecution images={[amImg07, amImg08]} />
        <AirMaxImpact images={[amImg09, amImg10, amImg11, amImg12]} />
      </div>
      <SectionTrigger
        bgColor="#bf93c3"
        className="air-max__section air-max__section--1"
        textColor="#524a88"
      />
      <SectionTrigger
        bgColor="#d1c2af"
        className="air-max__section air-max__section--2"
        textColor="#524a88"
      />
      <SectionTrigger
        bgColor="#524a88"
        className="air-max__section air-max__section--3"
        textColor="#bf93c3"
      />
      <SectionTrigger
        bgColor="#bf93c3"
        className="air-max__section air-max__section--4"
        textColor="#524a88"
      />
    </div>
  )
}

export default AirMaxContent;