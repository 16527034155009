import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';

function AirMaxSolutionMobile({ images }) {
  const [
    amImgMobile02,
    amImgMobile03,
    amImgMobile04,
    amImgMobile05,
  ] = images;

  return (
    <div className="am-solution">
      <h3 className="main-heading solution am-solution__heading">
        Solution
      </h3>
      <p className="main-copy am-solution__copy">
        Create a campaign that celebrates the AM90 as more than a sneaker, but rather a mindset that represents self-expression and personal identity - through radically fresh voices of today’s true pioneers of modern culture.
      </p>
      <blockquote className="blockquote am-solution__blockquote">
        &ldquo;A campaign that REINTRODUCES the Air Max 90 as AN agent OF self-expression…&rdquo;
      </blockquote>

      <Parallax className="air-max__img air-max__img--2" y={[40, -20]}>
        <ParallaxImage src={amImgMobile02} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/theskatekitchen/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @theskatekitchen
        </a>
      </Parallax>
      <div className="air-max__img air-max__img--3">
        <img src={amImgMobile03} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/daniellebknyc/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @daniellebknyc
        </a>
      </div>

      <Parallax className="air-max__img air-max__img--4" y={[40, -20]}>
        <ParallaxImage src={amImgMobile04} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/vevey1800/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @vevey1800
        </a>
      </Parallax>
      <div className="air-max__img air-max__img--5">
        <img src={amImgMobile05} alt="" />
      </div>
    </div>
  );
}

export default AirMaxSolutionMobile;