import React from 'react';
// components
import SectionTrigger from '../SectionTrigger';
import AirMaxChallengeMobile  from './AirMaxChallengeMobile';
import AirMaxExecutionMobile from './AirMaxExecutionMobile';
import AirMaxImpactMobile from './AirMaxImpactMobile';
import AirMaxSolutionMobile  from './AirMaxSolutionMobile';

function AirMaxMobile({ images, videos }) {
  const [
    amImgMobile01,
    amImgMobile02,
    amImgMobile03,
    amImgMobile04,
    amImgMobile05,
    amImgMobile06,
    amImgMobile07,
    amImgMobile08,
    amImgMobile09,
    amImgMobile10,
    amImgMobile11,
  ] = images;
  const [ amVid01 ] = videos;

  return (
    <div>
      <div className="air-max__container">
        <AirMaxChallengeMobile images={[amImgMobile01]} videos={[amVid01]} />
        <AirMaxSolutionMobile images={[amImgMobile02, amImgMobile03, amImgMobile04, amImgMobile05]} />
        <AirMaxExecutionMobile images={[amImgMobile06, amImgMobile07]} />
        <AirMaxImpactMobile images={[amImgMobile08, amImgMobile09, amImgMobile10, amImgMobile11]} />
      </div>
      <SectionTrigger
        bgColor="#bf93c3"
        className="air-max__section air-max__section--1"
        textColor="#524a88"
      />
      <SectionTrigger
        bgColor="#d1c2af"
        className="air-max__section air-max__section--2"
        textColor="#524a88"
      />
      <SectionTrigger
        bgColor="#524a88"
        className="air-max__section air-max__section--3"
        textColor="#bf93c3"
      />
      <SectionTrigger
        bgColor="#bf93c3"
        className="air-max__section air-max__section--4"
        textColor="#524a88"
      />
    </div>
  )
}

export default AirMaxMobile;