import React from 'react';
import { Parallax } from 'react-scroll-parallax';

function AirMaxImpactMobile({ images }) {
  const [amImgMobile08, amImgMobile09, amImgMobile10, amImgMobile11] = images;

  return (
    <div className="am-impact">
      <Parallax y={[40, -20]} className="am-impact__heading">
        <h3 className="main-heading impact">
          Impact Impact
        </h3>
      </Parallax>
      <p className="main-copy am-impact__copy">
        A campaign as effective at selling shoes as it was at inspiring the culture - through empowering stories speaking to young women, persons of color and youth through sports, art, fashion, skate and sneakers.
      </p>

      <div className="air-max__img air-max__img--8">
        <img src={amImgMobile08} alt="" />
      </div>
      <div className="global-box-shadow air-max__img air-max__img--9">
        <img src={amImgMobile09} alt="" />
      </div>
      <div className="air-max__img air-max__img--10">
        <img className="global-box-shadow" src={amImgMobile10} alt="" />
      </div>
      <div className="global-box-shadow air-max__img air-max__img--11">
        <img src={amImgMobile11} alt="" />
      </div>
    </div>
  );
}

export default AirMaxImpactMobile;