import React from 'react';
import { Parallax } from 'react-scroll-parallax';

function AirMaxImpact({ images }) {
  const [amImg09, amImg10, amImg11, amImg12] = images;

  return (
    <div className="am-impact">
      <Parallax y={[40, -20]} className="am-impact__heading">
        <h3 className="main-heading impact">
          Impact Impact
        </h3>
      </Parallax>
      <Parallax y={[40, -20]} className="am-impact__copy">
        <p className="main-copy">
          A campaign as effective at selling shoes as it was at inspiring the culture - through empowering stories speaking to young women, persons of color and youth through sports, art, fashion, skate and sneakers.
        </p>
      </Parallax>

      <div className="air-max__img air-max__img--9">
        <img src={amImg09} alt="" />
      </div>
      <div className="global-box-shadow air-max__img air-max__img--10">
        <img src={amImg10} alt="" />
      </div>
      <div className="air-max__img air-max__img--11">
        <img className="global-box-shadow" src={amImg11} alt="" />
      </div>
      <div className="global-box-shadow air-max__img air-max__img--12">
        <img src={amImg12} alt="" />
      </div>
    </div>
  );
}

export default AirMaxImpact;