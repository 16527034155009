import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/air-max.scss';
// hooks
import airMaxMedia from 'src/hooks/airMaxMedia';
import airMaxMobileMedia from 'src/hooks/airMaxMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import AirMaxContent from 'src/components/air-max-90/AirMaxContent';
import MobileAirMaxContent from 'src/components/air-max-90/AirMaxContentMobile';

// eslint-disable-next-line react/prop-types
function AirMaxPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = airMaxMobileMedia();
  const media = airMaxMedia();
  const amVid01 =
    'https://player.vimeo.com/external/433465622.hd.mp4?s=c97c11bda9463519ec0df2496e337a8c225201f4&profile_id=175&oauth2_token_id=1365998561';

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="Air Max 90 | Falkon Content" />
      <div className="air-max">
        {isDesktop
          ? (<AirMaxContent images={images} videos={[amVid01]} />)
          : (<MobileAirMaxContent images={mobileImages} videos={[amVid01]} />)
        }
      </div>
    </div>
  );
}

export default React.memo(AirMaxPage);
